<template>
  <div class="page">
    <div class="main-wrap flex flex-dc">

      <div class="card-wrap flex flex-ww">
        <div v-loading="loading._1" class="card-group flex flex-dc mb15" style="width: 100%;">
          <!-- tooltip-wrap start -->
          <div class="tooltip-wrap flex flex-ac flex-jb">
            <div class="title-wrap text-primary">銷售漏斗</div>
            <div class="filter-wrap global-filter-wrap flex flex-ac">
              <!--              <div class="filter-group flex flex-ac mr20">-->
              <!--                <div class="filter-label">地區:</div>-->
              <!--                <div class="form-control">-->
              <!--                  <el-select v-model="filter.area1" placeholder="地區" size="small">-->
              <!--                    <el-option label="All" value=""></el-option>-->
              <!--                  </el-select>-->
              <!--                </div>-->
              <!--              </div>-->

              <div class="filter-group flex flex-ac">
                <div class="filter-label">業務:</div>
                <div class="form-control">
                  <el-select v-model="filter_user1" placeholder="業務" size="small"
                             clearable filterable default-first-option remote :remote-method="searchUser">
                    <el-option v-for="item in userList" :key="'_1u_' + item.id" :label="item.nickname"
                               :value="item.id"/>
                  </el-select>
                </div>
              </div>
            </div>
          </div>
          <!-- tooltip-wrap end -->

          <!-- content-wrap start -->
          <div class="content-wrap flex-1 flex">
            <div class="left-wrap">
              <div class="area-wrap flex flex-dc flex-ja">
                <div class="area-group flex flex-ac flex-jc">
                  <div class="count">
                    {{ companyProgress && companyProgress.researchList ? companyProgress.researchList.length : 0 }}
                  </div>
                  <div class="block-wrap flex-1 flex flex-ac flex-jc">
                    <div class="label-cover flex flex-ww flex-ac flex-jc">
                      <div class="label-item flex flex-dc flex-ac flex-jc">
                        <span>調研</span>
                        <span>{{
                            companyProgress && companyProgress.researchList ? companyProgress.researchList.length : 0
                          }}</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="area-group flex flex-ac flex-jc">
                  <div class="count">{{
                      (companyProgress && companyProgress.emailList ? companyProgress.emailList.length : 0) + (companyProgress && companyProgress.phoneList ? companyProgress.phoneList.length : 0) + (companyProgress && companyProgress.socialList ? companyProgress.socialList.length : 0) + (companyProgress && companyProgress.videoList ? companyProgress.videoList.length : 0)
                    }}
                  </div>
                  <div class="block-wrap flex-1 flex flex-ac flex-jc">
                    <div class="label-cover flex flex-ww flex-ac flex-jc">
                      <div class="label-item flex flex-dc flex-ac flex-jc">
                        <span>郵件</span>
                        <span>{{
                            companyProgress && companyProgress.emailList ? companyProgress.emailList.length : 0
                          }}</span>
                      </div>
                      <div class="label-item flex flex-dc flex-ac flex-jc">
                        <span>電話</span>
                        <span>{{
                            companyProgress && companyProgress.phoneList ? companyProgress.phoneList.length : 0
                          }}</span>
                      </div>
                      <div class="label-item flex flex-dc flex-ac flex-jc">
                        <span>社群</span>
                        <span>{{
                            companyProgress && companyProgress.socialList ? companyProgress.socialList.length : 0
                          }}</span>
                      </div>
                      <div class="label-item flex flex-dc flex-ac flex-jc">
                        <span>影片</span>
                        <span>{{
                            companyProgress && companyProgress.videoList ? companyProgress.videoList.length : 0
                          }}</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="area-group flex flex-ac flex-jc">
                  <div class="count">{{
                      (companyProgress && companyProgress.visitList ? companyProgress.visitList.length : 0) + (companyProgress && companyProgress.inviteVisitList ? companyProgress.inviteVisitList.length : 0) + (companyProgress && companyProgress.solutionList ? companyProgress.solutionList.length : 0)
                    }}
                  </div>
                  <div class="block-wrap flex-1 flex flex-ac flex-jc">
                    <div class="label-cover flex flex-ww flex-ac flex-jc">
                      <div class="label-item flex flex-dc flex-ac flex-jc">
                        <span>拜訪</span>
                        <span>{{
                            companyProgress && companyProgress.visitList ? companyProgress.visitList.length : 0
                          }}</span>
                      </div>
                      <div class="label-item flex flex-dc flex-ac flex-jc">
                        <span>參訪</span>
                        <span>{{
                            companyProgress && companyProgress.inviteVisitList ? companyProgress.inviteVisitList.length : 0
                          }}</span>
                      </div>
                      <div class="label-item flex flex-dc flex-ac flex-jc">
                        <span>方案</span>
                        <span>{{
                            companyProgress && companyProgress.solutionList ? companyProgress.solutionList.length : 0
                          }}</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="area-group flex flex-ac flex-jc">
                  <div class="count">
                    {{ companyProgress && companyProgress.dealList ? companyProgress.dealList.length : 0 }}
                  </div>
                  <div class="block-wrap flex-1 flex flex-ac flex-jc">
                    <div class="label-cover flex flex-ww flex-ac flex-jc">
                      <div class="label-item flex flex-dc flex-ac flex-jc">
                        <span>成交</span>
                        <span>{{
                            companyProgress && companyProgress.dealList ? companyProgress.dealList.length : 0
                          }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="right-wrap flex-1 flex flex-dc">
              <div class="list-wrap flex flex-ac">
                <div class="list-group flex flex-dc">
                  <div class="list-title flex flex-ac">
                    <div class="label">調研</div>
                    <div class="count flex flex-ac flex-jc">{{
                        companyProgress && companyProgress.researchList ? companyProgress.researchList.length : 0
                      }}
                    </div>
                  </div>
                  <div class="list-item-wrap flex-1 flex flex-dc">
                    <div v-for="com in companyProgress.researchList" :key="'r_' + com.id" class="list-item">{{
                        com.name
                      }}
                    </div>
                  </div>
                </div>
              </div>

              <div class="list-wrap flex flex-ac">
                <div class="list-group flex flex-dc">
                  <div class="list-title flex flex-ac">
                    <div class="label">郵件</div>
                    <div class="count flex flex-ac flex-jc">{{
                        companyProgress && companyProgress.emailList ? companyProgress.emailList.length : 0
                      }}
                    </div>
                  </div>
                  <div class="list-item-wrap flex-1 flex flex-dc">
                    <div v-for="com in companyProgress.emailList" :key="'e_' + com.id" class="list-item">{{
                        com.name
                      }}
                    </div>
                  </div>
                </div>

                <div class="list-group flex flex-dc">
                  <div class="list-title flex flex-ac">
                    <div class="label">電話</div>
                    <div class="count flex flex-ac flex-jc">{{
                        companyProgress && companyProgress.phoneList ? companyProgress.phoneList.length : 0
                      }}
                    </div>
                  </div>
                  <div class="list-item-wrap flex-1 flex flex-dc">
                    <div v-for="com in companyProgress.phoneList" :key="'p_' + com.id" class="list-item">{{
                        com.name
                      }}
                    </div>
                  </div>
                </div>

                <div class="list-group flex flex-dc">
                  <div class="list-title flex flex-ac">
                    <div class="label">社群</div>
                    <div class="count flex flex-ac flex-jc">{{
                        companyProgress && companyProgress.socialList ? companyProgress.socialList.length : 0
                      }}
                    </div>
                  </div>
                  <div class="list-item-wrap flex-1 flex flex-dc">
                    <div v-for="com in companyProgress.socialList" :key="'s_' + com.id" class="list-item">{{
                        com.name
                      }}
                    </div>
                  </div>
                </div>

                <div class="list-group flex flex-dc">
                  <div class="list-title flex flex-ac">
                    <div class="label">影片</div>
                    <div class="count flex flex-ac flex-jc">{{
                        companyProgress && companyProgress.videoList ? companyProgress.videoList.length : 0
                      }}
                    </div>
                  </div>
                  <div class="list-item-wrap flex-1 flex flex-dc">
                    <div v-for="com in companyProgress.videoList" :key="'v_' + com.id" class="list-item">{{
                        com.name
                      }}
                    </div>
                  </div>
                </div>
              </div>

              <div class="list-wrap flex flex-ac">
                <div class="list-group flex flex-dc">
                  <div class="list-title flex flex-ac">
                    <div class="label">拜訪</div>
                    <div class="count flex flex-ac flex-jc">{{
                        companyProgress && companyProgress.visitList ? companyProgress.visitList.length : 0
                      }}
                    </div>
                  </div>
                  <div class="list-item-wrap flex-1 flex flex-dc">
                    <div v-for="com in companyProgress.visitList" :key="'vi_' + com.id" class="list-item">{{
                        com.name
                      }}
                    </div>
                  </div>
                </div>

                <div class="list-group flex flex-dc">
                  <div class="list-title flex flex-ac">
                    <div class="label">參訪</div>
                    <div class="count flex flex-ac flex-jc">{{
                        companyProgress && companyProgress.inviteVisitList ? companyProgress.inviteVisitList.length : 0
                      }}
                    </div>
                  </div>
                  <div class="list-item-wrap flex-1 flex flex-dc">
                    <div v-for="com in companyProgress.inviteVisitList" :key="'i_' + com.id" class="list-item">
                      {{ com.name }}
                    </div>
                  </div>
                </div>

                <div class="list-group flex flex-dc">
                  <div class="list-title flex flex-ac">
                    <div class="label">方案</div>
                    <div class="count flex flex-ac flex-jc">{{
                        companyProgress && companyProgress.solutionList ? companyProgress.solutionList.length : 0
                      }}
                    </div>
                  </div>
                  <div class="list-item-wrap flex-1 flex flex-dc">
                    <div v-for="com in companyProgress.inviteVisitList" :key="'so_' + com.id" class="list-item">
                      {{ com.name }}
                    </div>
                  </div>
                </div>
              </div>

              <div class="list-wrap flex flex-ac">
                <div class="list-group flex flex-dc">
                  <div class="list-title flex flex-ac">
                    <div class="label">成交</div>
                    <div class="count flex flex-ac flex-jc">{{
                        companyProgress && companyProgress.dealList ? companyProgress.dealList.length : 0
                      }}
                    </div>
                  </div>
                  <div class="list-item-wrap flex-1 flex flex-dc">
                    <div v-for="com in companyProgress.dealList" :key="'d_' + com.id" class="list-item">{{
                        com.name
                      }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- content-wrap end -->
        </div>

        <div v-loading="loading._2" class="card-group flex-1 flex flex-dc mr15" style="width: 49%;">
          <!-- tooltip-wrap start -->
          <div class="tooltip-wrap flex flex-ac flex-jb">
            <div class="title-wrap text-primary">銷售狀態</div>
            <div class="filter-wrap global-filter-wrap flex-1 flex flex-ac flex-je">
              <!--              <div class="filter-group flex flex-ac mr20">-->
              <!--                <div class="filter-label">地區:</div>-->
              <!--                <div class="form-control">-->
              <!--                  <el-select v-model="filter.area2" placeholder="地區" size="small">-->
              <!--                    <el-option label="All" value=""></el-option>-->
              <!--                  </el-select>-->
              <!--                </div>-->
              <!--              </div>-->

              <div class="filter-group flex flex-ac">
                <div class="filter-label">業務:</div>
                <div class="form-control">
                  <el-select v-model="filter_user2" placeholder="業務" size="small"
                             clearable filterable default-first-option remote :remote-method="searchUser">
                    <el-option v-for="item in userList" :key="'_2u_' + item.id" :label="item.nickname"
                               :value="item.id"/>
                  </el-select>
                </div>
              </div>
            </div>
          </div>
          <!-- tooltip-wrap end -->

          <!-- echart-wrap start -->
          <div class="echart-wrap flex1 flex flex-ac flex-jc">
            <div class="echart" id="echart-1" style="width:100%; height:100%"></div>
          </div>
          <!-- echart-wrap end -->
        </div>

        <div v-loading="loading._3" class="card-group flex-1 flex flex-dc">
          <!-- tooltip-wrap start -->
          <div class="tooltip-wrap flex flex-jb">
            <div class="title-wrap text-primary mr20">銷售動作</div>
            <div class="filter-wrap global-filter-wrap flex flex-ac flex-1 flex-ww flex-je">
              <!--              <div class="filter-group flex flex-ac mr20">-->
              <!--                <div class="filter-label">地區:</div>-->
              <!--                <div class="form-control">-->
              <!--                  <el-select v-model="filter.area3" placeholder="地區" size="small">-->
              <!--                    <el-option label="All" value=""></el-option>-->
              <!--                  </el-select>-->
              <!--                </div>-->
              <!--              </div>-->

              <div class="filter-group flex flex-ac">
                <div class="filter-label">業務:</div>
                <div class="form-control">
                  <el-select v-model="filter_user3" placeholder="業務" size="small"
                             clearable filterable default-first-option remote :remote-method="searchUser">
                    <el-option v-for="item in userList" :key="'_3u_' + item.id" :label="item.nickname"
                               :value="item.id"/>
                  </el-select>
                </div>
              </div>

              <div class="filter-group flex flex-ac mt10">
                <div class="filter-label">Date:</div>
                <div class="filter-control">
                  <el-date-picker
                      v-model="filter_date3"
                      clearable
                      type="daterange"
                      range-separator="-"
                      start-placeholder="From"
                      end-placeholder="To">
                  </el-date-picker>
                </div>
              </div>
            </div>
          </div>
          <!-- tooltip-wrap end -->

          <!-- echart-wrap start -->
          <div class="echart-wrap flex1 flex flex-ac flex-jc">
            <div class="echart" id="echart-2" style="width:100%; height:100%"></div>
          </div>
          <!-- echart-wrap end -->
        </div>
      </div>
      <!-- card-wrap end -->
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '../../styles/element-variables.scss';

$bg-1: #002060;
$bg-2: #1F4E79;
$bg-3: #2E75B6;
$bg-4: #5B9BD5;

.page ::v-deep {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  background-color: #f5f5f5;

  .main-wrap {
    height: 100%;
    overflow: hidden;
    padding: 15px;

    .card-wrap {
      overflow-y: auto;
      overflow-x: hidden;
      padding-right: 10px;
      padding-bottom: 5px;

      .card-group {
        height: 93vh;
        border-radius: 5px;
        box-shadow: 0 2px 5px rgba(#000, 0.2);
        padding: 15px;
        background-color: #fff;

        .tooltip-wrap {
          .title-wrap {
            font-weight: bold;
            font-size: calc(0.8vw + 8px);
          }
        }

        .content-wrap {
          margin-top: 30px;
          overflow: hidden;

          .left-wrap {
            width: 40%;
            height: 100%;

            .area-wrap {
              height: 100%;

              .area-group {
                margin-bottom: 15px;
                height: calc(25% - 15px);

                &:nth-child(1) {
                  .count {
                    color: $bg-1;
                  }

                  .block-wrap {
                    background-color: $bg-1;
                    clip-path: polygon(10% 100%, 0% 0%, 100% 0%, 90% 100%);

                    .label-cover {
                      width: 80%;
                    }
                  }
                }

                &:nth-child(2) {
                  .count {
                    color: $bg-2;
                    left: 10%;
                  }

                  .block-wrap {
                    background-color: $bg-2;
                    clip-path: polygon(20% 100%, 10% 0%, 90% 0%, 80% 100%);

                    .label-cover {
                      width: 60%;
                    }
                  }
                }

                &:nth-child(3) {
                  .count {
                    color: $bg-3;
                    left: 20%;
                  }

                  .block-wrap {
                    background-color: $bg-3;
                    clip-path: polygon(30% 100%, 20% 0%, 80% 0%, 70% 100%);

                    .label-cover {
                      width: 40%;
                    }
                  }
                }

                &:nth-child(4) {
                  .count {
                    color: $bg-4;
                    left: 30%;
                  }

                  .block-wrap {
                    background-color: $bg-4;
                    clip-path: polygon(40% 100%, 30% 0%, 70% 0%, 60% 100%);

                    .label-cover {
                      width: 20%;
                    }
                  }
                }

                .count {
                  font-weight: bold;
                  font-size: calc(0.9vw + 9px);
                  position: relative;
                  min-width: 2vw;
                }

                .block-wrap {
                  height: 100%;

                  .label-cover {

                    .label-item {
                      width: calc(2vw + 20px);
                      height: calc(2vw + 20px);
                      background-color: #fff;
                      border-radius: 100%;
                      margin: 5px;
                      font-weight: bold;
                      font-size: calc(0.5vw + 6px);
                      color: $--color-primary;
                      box-shadow: 0 0 5px rgba(#fff, 0.8);
                    }
                  }
                }
              }

            }
          }

          .right-wrap {
            height: 100%;
            margin-left: 2vw;

            .list-wrap {
              margin-bottom: 15px;
              border-radius: 5px;
              padding: 0 10px;
              height: calc(25% - 15px);

              &:nth-child(1) {
                background-color: rgba($bg-1, 0.08);

                .label {
                  color: $bg-1;
                }

                .count {
                  background-color: $bg-1;
                }

                .list-item {
                  border-color: $bg-1;
                  color: $bg-1;
                }

                .list-group:before {
                  background-color: $bg-1;
                }
              }

              &:nth-child(2) {
                background-color: rgba($bg-2, 0.08);

                .label {
                  color: $bg-2;
                }

                .count {
                  background-color: $bg-2;
                }

                .list-item {
                  border-color: $bg-2;
                  color: $bg-2;
                }

                .list-group:before {
                  background-color: $bg-2;
                }
              }

              &:nth-child(3) {
                background-color: rgba($bg-3, 0.08);

                .label {
                  color: $bg-3;
                }

                .count {
                  background-color: $bg-3;
                }

                .list-item {
                  border-color: $bg-3;
                  color: $bg-3;
                }

                .list-group:before {
                  background-color: $bg-3;
                }
              }

              &:nth-child(4) {
                background-color: rgba($bg-4, 0.08);

                .label {
                  color: $bg-4;
                }

                .count {
                  background-color: $bg-4;
                }

                .list-item {
                  border-color: $bg-4;
                  color: $bg-4;
                }

                .list-group:before {
                  background-color: $bg-4;
                }
              }

              .list-group {
                height: 90%;
                width: 24%;
                margin-left: 1%;
                overflow: hidden;
                position: relative;
                padding-left: 2%;

                &:before {
                  content: '';
                  position: absolute;
                  width: calc(0.4vw);
                  height: 70%;
                  top: 15%;
                  left: 0;
                  border-radius: 4px;
                }

                .list-title {
                  margin-bottom: 5px;

                  .label {
                    font-size: calc(0.7vw + 7px);
                    font-weight: bold;
                  }

                  .count {
                    width: calc(1vw + 10px);
                    height: calc(1vw + 10px);
                    color: #fff;
                    font-weight: bold;
                    border-radius: 100%;
                    margin-left: 10px;
                    font-size: calc(0.7vw + 7px);
                  }
                }

                .list-item-wrap {
                  overflow-y: auto;
                  padding-right: 5px;

                  .list-item {
                    border: 2px solid;
                    border-radius: 4px;
                    padding: 5px;
                    margin-bottom: 5px;
                    text-shadow: 0 0 1px;
                    cursor: pointer;
                    transition: all 0.1s ease-in-out;
                    font-size: calc(0.5vw + 5px);

                    &:hover {
                      background-color: rgba(#FF9800, 0.8);
                    }
                  }
                }
              }
            }
          }

        }

      }

    }

  }
}
</style>

<script>
import * as echarts from 'echarts'
import * as userApi from "@/apis/user";
import * as dashboardApi from "@/apis/dashboard";

export default {
  data() {
    return {
      loading: {
        _1: false,
        _2: false,
        _3: false,
      },
      // 瀏覽器基準寬度
      baseWindowWith: 0,
      // 圖表模型
      echart: {},
      filter_user1: null,
      filter_user2: null,
      filter_user3: null,
      filter_date3: null,
      filter: {
        area1: '',
        user1: '',
        area2: '',
        user2: '',
        area3: '',
        user3: '',
        date: ''
      },
      userList: [],
      companyProgress: {
        researchList: [],
        emailList: [],
        phoneList: [],
        socialList: [],
        videoList: [],
        visitList: [],
        inviteVisitList: [],
        solutionList: [],
        dealList: []
      },
      dashboardData3: [],
    }
  },
  created() {
    this.init();
  },
  watch: {
    filter_user1: function () {
      this.getCompanyProgressList();
    },
    filter_user2: function () {
      this.getCompanyStatusList();
    },
    filter_user3: function () {
      this.getSalesActionLogList();
    },
    filter_date3: function () {
      this.getSalesActionLogList();
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.createEcharts();
    });

    // 初始化窗口寬度賦值
    this.baseWindowWith = window.innerWidth;

    // 窗口發生變化時，重新賦值基礎寬度，並重新計算圖表字體大小和加載圖表
    window.onresize = () => {
      this.baseWindowWith = window.innerWidth;
      this.setEchartOption();
      this.resizeEchart();
    }
  },
  methods: {
    init() {
      this.getCompanyProgressList();
      this.getCompanyStatusList();
      this.getSalesActionLogList();
    },

    /*动态计算大小*/
    calcSize(coefficient) {
      return (7 + this.baseWindowWith * coefficient * 0.55 + 'px');
    },

    /*重新载入图表*/
    resizeEchart() {
      for (let e in this.echart) {
        this.echart[e] && this.echart[e].resize();
      }
    },

    /*创建图表*/
    createEcharts() {
      this.echart.echart_1 = this.echart.echart_1 || echarts.init(document.getElementById('echart-1'));
      this.echart.echart_2 = this.echart.echart_2 || echarts.init(document.getElementById('echart-2'));
      this.setEchartOption();
    },

    /*图表插值*/
    setEchartOption(echartIndex = 99, data = null) {
      // 柱狀圖1
      if (echartIndex === 1 || echartIndex === 99) {

        this.echart.echart_1.clear();

        let _data = [];
        if(data){
          data.map(item => {
            let group = {
              data: [item.aCount, item.bCount, item.cCount, item.dCount],
              type: 'bar',
              stack: 'a',
              name: item.customerManager.username
            }

            _data.push(group);
          });

          _data.push({
            data: [0, 0, 0, 0],
            type: 'bar',
            stack: 'a',
            label: {
              position: 'top',
              color: '#666',
              fontWeight: 'bold',
              formatter: (param) => {
                const res = [
                  [0, ...data.map(item => item.aCount)].reduce((a,b) => a+b),
                  [0, ...data.map(item => item.bCount)].reduce((a,b) => a+b),
                  [0, ...data.map(item => item.cCount)].reduce((a,b) => a+b),
                  [0, ...data.map(item => item.dCount)].reduce((a,b) => a+b)
                ];
                return res[param.dataIndex];
              }
            },
            itemStyle: {
              color: 'transparent'
            }
          });
        }

        this.echart.echart_1.setOption({
          title: {
            show: true,
            text: '客户数',
            top: '10%'
          },
          legend: {
            show: true,
            top: '10%',
            right: '0%'
          },
          grid: {
            top: '20%',
            left: '6%',
            right: '1%',
            bottom: '8%'
          },
          xAxis: {
            type: 'category',
            data: ['A', 'B', 'C', 'D'],
            axisLabel: {
              fontSize: this.calcSize(0.008),
              fontWeight: 'bold'
            },
          },
          yAxis: {
            type: 'value',
            axisLabel: {
              fontSize: this.calcSize(0.008)
            },
          },
          label: {
            show: true,
            color: '#fff',
            fontSize: this.calcSize(0.008)
          },
          series: _data
        });
      }
      // 柱狀圖2
      if (echartIndex === 2 || echartIndex === 99) {

        this.echart.echart_2.clear();

        let _data = [];
        if(data){
          data.map(item => {
            let group = {
              data: item.countList,
              type: 'bar',
              stack: 'a',
              name: item.companyManager.username
            }

            _data.push(group);
          });

          _data.push({
            data: [0, 0, 0, 0, 0, 0, 0, 0, 0],
            type: 'bar',
            stack: 'a',
            label: {
              position: 'top',
              color: '#666',
              fontWeight: 'bold',
              formatter: (param) => {
                const res = [
                  [0, ...data.map(item => item.countList[0])].reduce((a,b) => a+b),
                  [0, ...data.map(item => item.countList[1])].reduce((a,b) => a+b),
                  [0, ...data.map(item => item.countList[2])].reduce((a,b) => a+b),
                  [0, ...data.map(item => item.countList[3])].reduce((a,b) => a+b),
                  [0, ...data.map(item => item.countList[4])].reduce((a,b) => a+b),
                  [0, ...data.map(item => item.countList[5])].reduce((a,b) => a+b),
                  [0, ...data.map(item => item.countList[6])].reduce((a,b) => a+b),
                  [0, ...data.map(item => item.countList[7])].reduce((a,b) => a+b),
                  [0, ...data.map(item => item.countList[8])].reduce((a,b) => a+b)
                ];
                return res[param.dataIndex];
              }
            },
            itemStyle: {
              color: 'transparent'
            }
          });
        }

        this.echart.echart_2.setOption({
          title: {
            show: true,
            text: '動作數',
            top: '5%'
          },
          legend: {
            show: true,
            top: '5%',
            right: '0%'
          },
          grid: {
            top: '15%',
            left: '6%',
            right: '1%',
            bottom: '8%'
          },
          xAxis: {
            type: 'category',
            data: ['調研', '郵件', '電話', '社群', '影片', '拜訪', '參訪', '解決方案', '成交'],
            axisLabel: {
              fontSize: this.calcSize(0.008),
              fontWeight: 'bold',
              interval: 0,
              rotate: 35,
              margin: 20
            }
          },
          yAxis: {
            type: 'value',
            axisLabel: {
              fontSize: this.calcSize(0.008)
            },
          },
          label: {
            show: true,
            color: '#fff',
            fontSize: this.calcSize(0.008)
          },
          series: _data
        });
      }
    },

    /**
     * 搜索用户
     */
    searchUser(value) {
      userApi.list(value).then((res) => {
        this.userList = res;
      })
    },

    /**
     * 銷售漏斗
     */
    getCompanyProgressList() {
      this.loading._1 = true;
      dashboardApi.getCompanyProgressList(this.filter_user1).then((res) => {
        this.companyProgress = res;
      }).finally(() => {
        this.loading._1 = false;
      });
    },
    /**
     * 銷售狀態
     */
    getCompanyStatusList() {
      this.loading._2 = true;
      dashboardApi.getCompanyStatusList(this.filter_user2).then((res) => {
        this.setEchartOption(1, res);
      }).finally(() => {
        this.loading._2 = false;
      })

    },
    /**
     * 銷售動作
     */
    getSalesActionLogList() {
      this.loading._3 = true;

      const startDate = this.filter_date3 ? this.filter_date3[0] : null;
      const endDate = this.filter_date3 ? this.filter_date3[1] : null;
      dashboardApi.getSalesActionList(this.filter_user3, startDate, endDate).then((res) => {
        this.setEchartOption(2, res);
      }).finally(() => {
        this.loading._3 = false;
      })
    }
  }
}
</script>
