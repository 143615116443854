import request from './request';

/**
 * 获取销售进度统计
 */
export function getCompanyProgressList(managerId) {
  return request({
    url: `/crm/dashboard/getCompanyProgressList`,
    method: 'get',
    params: {managerId}
  })
}

/**
 * 获取销售状态统计
 */
export function getCompanyStatusList(managerId) {
  return request({
    url: `/crm/dashboard/getCompanyStatusList`,
    method: 'get',
    params: {managerId}
  })
}


/**
 * 获取销售动作统计
 */
export function getSalesActionList(managerId, startDate, endDate) {
  return request({
    url: `/crm/dashboard/getSalesActionList`,
    method: 'get',
    params: {managerId, startDate, endDate}
  })
}
